var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import React, { forwardRef, useState } from 'react';
import { Space } from '@/shared/ui/Space/Space';
import { Icon } from '@/shared/ui/Icon/Icon';
import { ReactComponent as Eye } from '@/shared/assets/icons/Eye.svg';
import { ReactComponent as EyeOff } from '@/shared/assets/icons/EyeOff.svg';
import { Text } from '@/shared/ui/Text/Text';
import { HelpTooltip } from '@/shared/ui/HelpTooltip/HelpTooltip';
const PasswordInput = forwardRef((_a, ref) => {
    var { value, css, autocomplete, cssButton, onChange, iconInside, typeInput, type, error, label, tooltip, required, disabled, onBlur } = _a, props = __rest(_a, ["value", "css", "autocomplete", "cssButton", "onChange", "iconInside", "typeInput", "type", "error", "label", "tooltip", "required", "disabled", "onBlur"]);
    const [visible, setVisible] = useState(false);
    const handleChange = (event) => {
        if (onChange) {
            onChange(event);
        }
    };
    const handleChangeVisible = () => {
        if (disabled) {
            return;
        }
        setVisible((prevState) => !prevState);
    };
    const handleBlur = (event) => {
        if (onBlur) {
            onBlur(event);
        }
    };
    return (_jsxs(Space, { direction: "vertical", css: { position: 'relative', width: '100%' }, children: [label &&
                _jsx(S.Label, { css: { width: '100%' }, children: _jsxs(Space, { children: [_jsx(Text, { type: "labelInput", children: label }), required && _jsx(Text, { type: "error", children: "*" }), tooltip &&
                                _jsx(HelpTooltip, { cssForContainer: { marginLeft: '6px', width: '18px', height: '18px' }, mode: "dark", content: tooltip })] }) }), _jsx(S.PasswordInput, Object.assign({ typeInput: typeInput, value: value, iconInside: iconInside, onBlur: handleBlur, type: !visible ? 'password' : 'text', ref: ref, disabled: disabled, autoFocus: false, autoComplete: autocomplete || 'on', error: error && true, onChange: (event) => handleChange(event), css: css }, props)), _jsx(S.ButtonPassword, { css: Object.assign({ margin: !visible ? '13px 6px' : '11px 6px' }, cssButton), onClick: handleChangeVisible, children: !visible ?
                    _jsx(Icon, { css: {
                            width: '20px',
                            height: '20px',
                        }, stroke: disabled ? 'rgb(164, 169, 181)' : '#3C435A', IconItem: Eye })
                    :
                        _jsx(Icon, { stroke: disabled ? 'rgb(164, 169, 181)' : '#3C435A', css: {
                                width: '20px',
                                height: '20px',
                                marginBottom: '4px',
                            }, IconItem: EyeOff }) })] }));
});
export { PasswordInput };
