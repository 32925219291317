import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Dialog, Space, Text } from '@/shared/ui';
import * as S from './style';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setAddNewShop, setGetBonusMailingModal } from '@/entity/ModalsEntity/modals.slice';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
const GetBonusMailing = ({ open }) => {
    const dispatch = useTypedDispatch();
    const bonusAmount = useTypedSelector((state) => state.mailingsReducer.bonusAmount);
    const handleAddNewStore = () => {
        dispatch(setAddNewShop(true));
        dispatch(setGetBonusMailingModal(false));
    };
    const handleClose = () => {
        dispatch(setGetBonusMailingModal(false));
    };
    return (_jsxs(Dialog, { open: open, closeIcon: true, onOpenChange: handleClose, css: { width: '600px' }, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u041F\u0440\u0438\u0432\u0435\u0442" }), _jsxs(Text, { css: { marginBottom: '24px' }, type: "default", children: ["\u041C\u044B \u0440\u0430\u0434\u044B \u0442\u0435\u0431\u044F \u0432\u0438\u0434\u0435\u0442\u044C \u0432 \u043C\u043E\u0434\u0443\u043B\u0435 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 Harvester! ", _jsx("br", {}), "\u041C\u044B \u0434\u0430\u0440\u0438\u043C \u0432\u0430\u043C ", !!bonusAmount ? bonusAmount + 500 : 500, " \u0431\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u044B\u0445 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439!"] }), _jsxs(S.GetBonusBackground, { children: [_jsxs(Space, { children: [_jsx(Text, { type: "labelInput", children: "500" }), !!bonusAmount && _jsxs(Text, { css: { color: '$acceptButton', marginLeft: '4px' }, type: "labelInput", children: ["+ ", bonusAmount] })] }), _jsx(Text, { css: { color: !!bonusAmount ? '$acceptButton' : '' }, type: "default", children: "\u041F\u043E\u0434\u0430\u0440\u043E\u0447\u043D\u044B\u0445 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0439" })] }), _jsx(Text, { css: { marginBottom: '12px' }, type: "default", children: "\u0414\u043B\u044F \u0437\u0430\u043F\u0443\u0441\u043A\u0430 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438 \u043F\u043E\u0434\u043A\u043B\u044E\u0447\u0438\u0442\u0435 \u0432\u0430\u0448 \u043F\u0435\u0440\u0432\u044B\u0439 \u043C\u0430\u0433\u0430\u0437\u0438\u043D." }), _jsx(Button, { onClick: handleAddNewStore, children: "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u043C\u0430\u0433\u0430\u0437\u0438\u043D" })] }));
};
export { GetBonusMailing };
