var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { MailingStatusEnum, ProductTypeEnum } from '@/app/interfaces/Mailings/types';
import { get, orderBy } from 'lodash';
import { getMailing, postMailing, putMailing, removeMailing } from '@/shared/api/mailing';
import { BASE_FETCH_STATUS } from '@/shared/api/constants/baseFetch.status';
import { START_FETCH_STATUS } from '@/shared/api/constants/startFetch.status';
import { updateUserProducts } from '@/entity/ProfileEntity/profile.slice';
import Cookies from 'universal-cookie';
import { setMailingsSideBarModule } from '@/entity/SidebarEntity/sidebar.slice';
import { StatusFilterEnum } from '@/app/providers/locales/StatusMailing/status.enum';
import { displayError } from '@/shared/utils/displayError/displayError';
const cookies = new Cookies();
const initialState = {
    mailings: [],
    mailingsStats: null,
    balance: 0,
    bonusAmount: 0,
    mailingsStatus: BASE_FETCH_STATUS,
    mailingIdStatus: BASE_FETCH_STATUS,
};
const getMailings = createAsyncThunk('mailings/getMailings', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const resp = yield getMailing(`requests?limit=${payload === null || payload === void 0 ? void 0 : payload.limit}&offset=${payload === null || payload === void 0 ? void 0 : payload.offset}`);
        return get(resp.data, 'mailingsOzon', []);
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getMailingById = createAsyncThunk('mailings/getMailingById', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield getMailing(`request?id=${payload}`);
        return response.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const updateMailingById = createAsyncThunk('mailings/updateMailingById', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield putMailing('mailing', payload);
        return response.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getMailingsStats = createAsyncThunk('mailings/getMailingsStats', (_, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield getMailing('stats');
        return response.data;
    }
    catch (error) {
        const err = error;
        if (err.response) {
            if (err.response.status !== 403) {
                displayError(err);
            }
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const getBalanceForMailings = createAsyncThunk('mailings/getBalanceForMailings', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const resp = yield getMailing('balance');
        return resp.data;
    }
    catch (error) {
        const err = error;
        if (((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) === 401 || (err === null || err === void 0 ? void 0 : err.response.status) === 403) {
            return thunkAPI.rejectWithValue(error);
        }
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const startMailingTrial = createAsyncThunk('mailing/startMailingTrial', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postMailing('trial', payload);
        cookies.set('token', response.headers['grpc-metadata-authorization'], { maxAge: 60 * 60 * 168, path: '/' });
        if (response.headers['grpc-metadata-authorization']) {
            thunkAPI.dispatch(updateUserProducts(ProductTypeEnum.USER_ACTIVE_PRODUCT_MAILING));
            thunkAPI.dispatch(setMailingsSideBarModule({ value: true }));
            return response.data;
        }
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const createNewMailing = createAsyncThunk('mailing/createNewMailing', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postMailing('mailing', payload);
        return Object.assign(Object.assign({}, payload), { id: response.data.id });
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const createMailingWithUploader = createAsyncThunk('mailing/createMailingWithUploader', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield postMailing('uploader', payload);
        return Object.assign(Object.assign({}, payload), { id: response.data.id });
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const removeMailingById = createAsyncThunk('mailings/removeMailing', (payload, thunkAPI) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield removeMailing(`mailing?id=${payload}`);
        return { id: payload, data: response.data };
    }
    catch (error) {
        const err = error;
        if (err.response) {
            displayError(err);
            return thunkAPI.rejectWithValue(err.response.data);
        }
        return thunkAPI.rejectWithValue(error);
    }
}));
const mailingSlice = createSlice({
    name: 'mailingSlice',
    initialState,
    reducers: {
        setBalance: (state, { payload }) => {
            state.balance = payload;
        },
        clearMailing: (state) => {
            state.mailings = [];
            state.mailingsStatus = BASE_FETCH_STATUS;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getMailings.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getMailings.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(getMailings.fulfilled, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailings = orderBy(payload, ['createdAt'], ['desc']);
        });
        builder.addCase(getMailingById.pending, (state) => {
            state.mailingIdStatus = START_FETCH_STATUS;
        });
        builder.addCase(getMailingById.rejected, (state, { payload }) => {
            state.mailingIdStatus.isLoading = false;
            state.mailingIdStatus.error = payload;
        });
        builder.addCase(getMailingById.fulfilled, (state) => {
            state.mailingIdStatus.isLoading = false;
        });
        builder.addCase(updateMailingById.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(updateMailingById.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(updateMailingById.fulfilled, (state) => {
            state.mailingsStatus.isLoading = false;
        });
        builder.addCase(createNewMailing.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(createNewMailing.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(createNewMailing.fulfilled, (state, { payload }) => {
            var _a;
            state.mailingsStatus.isLoading = false;
            state.mailings.push(payload);
            if (payload.mailingStatus !== MailingStatusEnum.Draft) {
                state.balance = Number(state.balance) - ((_a = payload === null || payload === void 0 ? void 0 : payload.posting_ids) === null || _a === void 0 ? void 0 : _a.length);
            }
        });
        builder.addCase(removeMailingById.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(removeMailingById.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(removeMailingById.fulfilled, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailings = state.mailings.map((mailing) => {
                if (mailing.id === payload.id) {
                    const copy = Object.assign({}, mailing);
                    return Object.assign(Object.assign({}, copy), { mailingStatus: StatusFilterEnum.MAILING_STATUS_CANCELED });
                }
                return mailing;
            });
        });
        builder.addCase(getBalanceForMailings.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getBalanceForMailings.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(getBalanceForMailings.fulfilled, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.balance = payload.balance.mailingBalance;
        });
        builder.addCase(getMailingsStats.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(getMailingsStats.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(getMailingsStats.fulfilled, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStats = payload;
        });
        builder.addCase(startMailingTrial.pending, (state) => {
            state.mailingsStatus = START_FETCH_STATUS;
        });
        builder.addCase(startMailingTrial.rejected, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            state.mailingsStatus.error = payload;
        });
        builder.addCase(startMailingTrial.fulfilled, (state, { payload }) => {
            state.mailingsStatus.isLoading = false;
            if (payload.amount) {
                state.bonusAmount = +payload.amount;
            }
        });
    },
});
export default mailingSlice.reducer;
export const { clearMailing, setBalance } = mailingSlice.actions;
export { getMailings, createNewMailing, getBalanceForMailings, getMailingsStats, startMailingTrial, getMailingById, updateMailingById, removeMailingById, };
