import { styled } from '@/app/styles/mainStyles';
export const SideBarContent = styled('section', {
    '@desktop': {
        padding: '20px 15px',
        '&[data-state-open="false"]': {
            height: '104px',
        },
        height: '100vh',
    },
    '@mobile': {
        padding: '0',
        height: '100vh',
        '&[data-state-open="false"]': {
            padding: '20px 15px',
            height: '104px',
        },
    },
});
export const SideBarWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});
export const SideBarIcon = styled('img', {
    height: '36px',
    marginBottom: '40px',
});
export const SideBarBodyWrapper = styled('div', {
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '12px 16px',
    background: '#F7F7F7',
    borderRadius: '8px',
    height: '100%',
});
export const SideBarScrollWrapper = styled('div', {
    display: 'flex',
    overflow: 'auto',
    height: '100%',
    flexDirection: 'column',
    opacity: 1,
    transition: '0.3s ease-in-out',
    variants: {
        isClosed: {
            true: {
                display: 'none',
                height: '120px',
                transition: '0.3s ease-in-out',
            },
        },
    },
});
export const SideBarButtonContainer = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    variants: {
        isOpen: {
            true: {
                maxHeight: '1000px',
                overflow: 'hidden',
                transition: 'max-height 0.3s cubic-bezier(1, 0, 1, 0)',
            },
            false: {
                maxHeight: '0px',
                transition: 'max-height 0.3s cubic-bezier(0, 1, 0, 1)',
            },
        },
    },
});
export const PrimarySideBarButtonVisible = styled('div', {
    marginTop: '20px',
    variants: {
        visible: {
            false: {
                visibility: 'hidden',
            },
        },
    },
});
export const SideBarBodyIconWrapper = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: '45px,',
});
export const SideBarBodySectionWrapper = styled('div', {
    display: 'flex',
    flexDirection: ' column',
    padding: '12px 11px',
    cursor: 'pointer',
    background: '#FFFFFF',
    borderRadius: '8px',
    margin: '8px 0px',
});
export const SideBarBodySectionButton = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 4px 10px 10px',
    cursor: 'pointer',
    variants: {
        active: {
            true: {
                background: 'rgba(84, 95, 201, 0.2)',
                borderRadius: '8px',
            },
        },
    },
});
export const ToggleIconCollapse = styled('svg', {
    width: '10px',
    height: '12px',
    margin: '0 0 0 1px',
    viewBox: '0 0 10 12',
    fill: 'none',
    stroke: '$textBlackColor',
    xmlns: 'http://www.w3.org/2000/svg',
    version: '1.1',
});
export const ToggleButton = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '19px',
    height: '34px',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    '& > svg': {
        transition: '0.3s ease-in-out',
    },
    '@mobile': {
        '&[data-state-open="true"]': {
            '& > svg': {
                transform: 'rotate(180deg)',
            },
        },
        '& > svg': {
            transform: 'rotate(0deg)',
        },
    },
    '@desktop': {
        '& > svg': {
            transform: 'rotate(-90deg)',
        },
        '&[data-state-open="true"]': {
            '& > svg': {
                transform: 'rotate(90deg)',
            },
        },
    },
});
export const SideBarSectionButtonRow = styled('div', {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});
export const SideBarBodySectionButtonTextMain = styled('div', {
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '22px',
    color: '#414BB2',
    variants: {
        active: {
            true: {
                color: '#2A3389',
            },
        },
    },
});
export const SideBarFooter = styled('div', {
    marginTop: '12px',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-between',
});
export const CloseSideBarFooter = styled('div', {
    display: 'flex',
    padding: '0 16px',
    justifyContent: 'space-between',
});
export const IconContainer = styled('div', {
    width: '40px',
    height: '34px',
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(243, 247, 251, 1)',
    '&:hover': {
        cursor: 'pointer',
        boxShadow: '0 5px 20px rgba(0, 0, 0, 0.2)',
    },
});
