import * as yup from 'yup';
const regExpPhone = /^((8|\+374|\+994|\+995|\+375|\+7|\+380|\+38|\+996|\+998|\+993)[\- ]?)?\(?\d{3,5}\)?[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}[\- ]?\d{1}(([\- ]?\d{1})?[\- ]?\d{1})?$/;
export const personalDataSchemaWithName = yup.object({
    name: yup
        .string()
        .min(1, { text: 'required' })
        .required({ text: 'required' }),
    phone: yup
        .string()
        .test({
        name: 'phone',
        message: { text: 'required' },
        test: ((value) => regExpPhone.test(value)),
    })
        .required({ text: 'required' }),
});
// export const personalDataSchemaWithoutName = yup.object({
//   telegram: yup
//     .string()
//     .min(2, { text: 'required' })
//     .required({ text: 'required' }),
//   phone: yup
//     .string()
//     .test({
//       name: 'phone',
//       message: { text: 'required' },
//       test: ((value: any) => regExpPhone.test(value)),
//     })
//     .required({ text: 'required' }),
// });
