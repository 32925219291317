var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Button, Space, Text } from '@/shared/ui';
import React, { useState } from 'react';
import { ExperienceNameEnum, MarketplaceListEnum, OwnRoleEnum, SkuListEnum, TotalMarketsEnum, } from '@/entity/MailingListEntity/types';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { getProfile, setNotification, updateUserInfo } from '@/entity/ProfileEntity/profile.slice';
const userRole = [
    {
        value: OwnRoleEnum.OWN_ROLE_I_WANT_TO_SELL_ON_MARKETPLACES,
        name: 'Хочу начать продавать на маркетплейсах',
    },
    {
        value: OwnRoleEnum.OWN_ROLE_I_SELL_IT_MYSELF,
        name: 'Сам продаю',
    },
    {
        value: OwnRoleEnum.OWN_ROLE_I_WORK_IN_A_COMPANY,
        name: 'Работаю в компании',
    },
    {
        value: OwnRoleEnum.OWN_ROLE_FOUNDED_MY_COMPANY,
        name: 'Основал свою компанию',
    },
];
const mpList = [
    {
        name: 'Ozon',
        value: MarketplaceListEnum.Ozon,
    },
    {
        name: 'Wildberries',
        value: MarketplaceListEnum.Wildberries,
    },
    {
        name: 'Яндекс Маркет',
        value: MarketplaceListEnum.YandexMarket,
    },
    {
        name: 'СберМегаМаркет',
        value: MarketplaceListEnum.SberMegaMarket,
    },
];
const experienceList = [
    {
        name: 'Еще не работаю',
        value: ExperienceNameEnum.EXPERIENCE_NOT_YET_WORK,
    },
    {
        name: 'Менее полугода',
        value: ExperienceNameEnum.EXPERIENCE_LESS_THEN_6_MONTH,
    },
    {
        name: 'Менее года',
        value: ExperienceNameEnum.EXPERIENCE_LESS_THEN_1_YEAR,
    },
    {
        name: '1-3 года',
        value: ExperienceNameEnum.EXPERIENCE_BETWEEN_1_AND_3_YEARS,
    },
    {
        name: '3-5 лет',
        value: ExperienceNameEnum.EXPERIENCE_BETWEEN_3_AND_5_YEARS,
    },
    {
        name: 'Более 5 лет',
        value: ExperienceNameEnum.EXPERIENCE_MORE_THEN_5_YEARS,
    },
];
const skuList = [
    {
        name: 'Несколько товаров',
        value: SkuListEnum.SKU_SEVERAL,
    },
    {
        name: 'Менее 50',
        value: SkuListEnum.SKU_LESS_THEN_50,
    },
    {
        name: '50-100',
        value: SkuListEnum.SKU_BETWEEN_50_AND_100,
    },
    {
        name: '100-300',
        value: SkuListEnum.SKU_BETWEEN_100_AND_300,
    },
    {
        name: '300-500',
        value: SkuListEnum.SKU_BETWEEN_300_AND_500,
    },
    {
        name: '500-1000',
        value: SkuListEnum.SKU_BETWEEN_500_AND_1000,
    },
    {
        name: '1000-3000',
        value: SkuListEnum.SKU_BETWEEN_1000_AND_3000,
    },
    {
        name: '3000+',
        value: SkuListEnum.SKU_MORE_THEN_3000,
    },
];
const marketCount = [
    {
        name: 'Еще нет',
        value: TotalMarketsEnum.TOTAL_MARKETS_NOT_YET,
    },
    {
        name: '1',
        value: TotalMarketsEnum.TOTAL_MARKETS_ONE,
    },
    {
        name: '2-3',
        value: TotalMarketsEnum.TOTAL_MARKETS_TWO_OR_THREE,
    },
    {
        name: '4-10',
        value: TotalMarketsEnum.TOTAL_MARKETS_BETWEEN_FOUR_AND_TEN,
    },
    {
        name: '10+',
        value: TotalMarketsEnum.TOTAL_MARKETS_MORE_THEN_TEN,
    },
];
const SalesExperience = ({ handleCloseModal }) => {
    const dispatch = useTypedDispatch();
    const [value, setValue] = useState({
        markets: [],
        ownRole: '',
        experience: '',
        sku: '',
        totalMarkets: '',
    });
    const validate = () => {
        const allValue = Object.entries(value);
        return allValue.every(([key, val]) => val.length > 0);
    };
    const getActive = (field, active) => {
        var _a;
        if (field === 'markets') {
            return (_a = value.markets) === null || _a === void 0 ? void 0 : _a.find((el) => el === active);
        }
        else {
            return value[field] === active;
        }
    };
    const handleClick = (field, updateValue) => {
        if (field === 'markets') {
            setValue((prevState) => {
                if (prevState.markets.find((el) => el === updateValue)) {
                    return Object.assign(Object.assign({}, prevState), { markets: prevState.markets.filter((el) => el !== updateValue) });
                }
                else {
                    return Object.assign(Object.assign({}, prevState), { markets: [...prevState.markets, updateValue] });
                }
            });
        }
        else {
            setValue((prevState) => {
                return Object.assign(Object.assign({}, prevState), { [field]: updateValue });
            });
        }
    };
    const handleUpdate = () => __awaiter(void 0, void 0, void 0, function* () {
        if (validate()) {
            try {
                yield dispatch(updateUserInfo(value));
            }
            catch (e) {
                console.log(e);
            }
        }
        try {
            if (handleCloseModal) {
                dispatch(getProfile());
                dispatch(setNotification(false));
                handleCloseModal();
            }
        }
        catch (e) {
            console.log(e);
        }
    });
    const getButtons = (list, field) => {
        return (_jsx(S.ButtonContainer, { children: list.map((el) => {
                return _jsx(Button, { onClick: () => handleClick(field, el.value), withoutBorder: true, type: getActive(field, el.value) ? 'primary' : 'default', children: el.name });
            }) }));
    };
    return (_jsx(S.SalesExperienceContainer, { children: _jsx(S.SalesExperienceFormContainer, { children: _jsxs(S.SalesExperienceFormWrapper, { children: [_jsxs(Space, { direction: "vertical", children: [_jsx(Text, { type: "labelInput", children: "\u041A\u0430\u043A\u0430\u044F \u0440\u043E\u043B\u044C \u043E\u043F\u0438\u0441\u044B\u0432\u0430\u0435\u0442 \u0432\u0430\u0441 \u043B\u0443\u0447\u0448\u0435 \u0432\u0441\u0435\u0433\u043E?" }), getButtons(userRole, 'ownRole')] }), _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { type: "labelInput", children: "\u041D\u0430 \u043A\u0430\u043A\u0438\u0445 \u043C\u0430\u0440\u043A\u0435\u0442\u043F\u043B\u0435\u0439\u0441\u0430\u0445 \u0440\u0430\u0431\u043E\u0442\u0430\u0435\u0442\u0435?" }), getButtons(mpList, 'markets')] }), _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { type: "labelInput", children: "\u0421\u043A\u043E\u043B\u044C\u043A\u043E \u043B\u0435\u0442 \u0440\u0430\u0431\u043E\u0442\u0430\u0435\u0442\u0435 \u043D\u0430 \u043C\u0430\u0440\u043A\u0435\u0442\u043F\u043B\u0435\u0439\u0441\u0430\u0445?" }), getButtons(experienceList, 'experience')] }), _jsxs(Space, { direction: "vertical", children: [_jsx(Text, { type: "labelInput", children: "\u0421\u043A\u043E\u043B\u044C\u043A\u043E SKU?" }), getButtons(skuList, 'sku')] }), _jsxs(Space, { css: { marginBottom: '12px' }, direction: "vertical", children: [_jsx(Text, { type: "labelInput", children: "\u0421\u043A\u043E\u043B\u044C\u043A\u043E \u043C\u0430\u0433\u0430\u0437\u0438\u043D\u043E\u0432 \u0432\u0441\u0435\u0433\u043E, \u043D\u0430 \u0432\u0441\u0435\u0445 \u043C\u0430\u0440\u043A\u0435\u0442\u043F\u043B\u0435\u0439\u0441\u0430\u0445" }), getButtons(marketCount, 'totalMarkets')] }), _jsx("div", { children: _jsx(Button, { onClick: handleUpdate, type: "payment", children: "\u041E\u0431\u043D\u043E\u0432\u0438\u0442\u044C" }) })] }) }) }));
};
export { SalesExperience };
