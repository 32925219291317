import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import * as S from './style';
import { HelpTooltip } from '@/shared/ui/HelpTooltip/HelpTooltip';
const Text = ({ children, css, type, hover, onClick, id, ellipse, activeUnderline, decoration, active, algin, disable, tooltip, label, error, colorText, }) => {
    const handleClick = (event) => {
        if (onClick) {
            onClick(event);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(S.Text, { title: label, algin: algin, id: id, disable: disable, ellipse: ellipse, colorText: colorText, activeUnderline: activeUnderline, hover: hover, onClick: handleClick, active: active, error: !!error, decoration: decoration, type: type, css: css, children: children }), tooltip &&
                _jsx(HelpTooltip, { mode: "gray", content: tooltip, cssForContainer: { margin: '0 20px 0 4px', width: '12px', height: '12px', display: 'flex', alignItems: 'center' }, css: { margin: '0', width: '12px', height: '12px' } })] }));
};
export { Text };
