var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import * as S from './style';
import { Button, Input, Space } from '@/shared/ui';
import React from 'react';
import { useForm } from 'react-hook-form';
import { ToastError } from '@/widgets/ui/Toast/ToastError/ToastError';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { updateUserInfo } from '@/entity/ProfileEntity/profile.slice';
import { yupResolver } from '@hookform/resolvers/yup';
import { personalDataSchemaWithName, } from '@/widgets/ui/AuthFlow/ui/PersonalData/schema';
import { useTypedSelector } from '@/shared/hooks/useTypedSelector/useTypedSelector';
const PersonalData = ({ incrementStep }) => {
    var _a;
    const dispatch = useTypedDispatch();
    const userData = useTypedSelector((state) => state.profileReducer.profile);
    const gerResolver = () => {
        return personalDataSchemaWithName;
    };
    const { register, getValues, trigger, formState: { errors } } = useForm({
        mode: 'onChange',
        defaultValues: {
            name: '',
            phone: '',
        },
        resolver: yupResolver(gerResolver()),
    });
    const handleUpdateUserInfo = () => __awaiter(void 0, void 0, void 0, function* () {
        const userInfo = getValues();
        const isValid = yield trigger();
        if (isValid) {
            const resp = yield dispatch(updateUserInfo({
                fullName: `${userInfo.name}`,
                phone: userInfo.phone,
            }));
            if (!resp.payload.code) {
                incrementStep();
            }
        }
        else {
            ToastError('Заполните все обязательные поля');
        }
    });
    const getUiForFullName = () => {
        var _a;
        if (!(userData === null || userData === void 0 ? void 0 : userData.fullName)) {
            return (_jsx(_Fragment, { children: _jsx(Space, { children: _jsx(Input, Object.assign({}, register('name'), { label: "\u0418\u043C\u044F", css: { width: '100%' }, error: (_a = errors.name) === null || _a === void 0 ? void 0 : _a.message, placeholder: "\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0438\u043C\u044F", typeInput: "auth" })) }) }));
        }
        return null;
    };
    return (_jsx(S.PersonalDataContainer, { children: _jsx(S.FormContainer, { children: _jsxs(S.FormWrapper, { children: [getUiForFullName(), _jsx(Space, { children: _jsx(Input, Object.assign({}, register('phone'), { label: "\u0422\u0435\u043B\u0435\u0444\u043E\u043D", css: { width: '100%' }, error: (_a = errors.phone) === null || _a === void 0 ? void 0 : _a.message, placeholder: "+7", tooltip: "\u041C\u044B \u043D\u0435 \u0431\u0443\u0434\u0435\u043C \u043D\u0430\u0437\u043E\u0439\u043B\u0438\u0432\u043E \u0437\u0432\u043E\u043D\u0438\u0442\u044C \u043F\u043E \u043B\u044E\u0431\u043E\u043C\u0443 \u0432\u043E\u043F\u0440\u043E\u0441\u0443. \u0412\u043E\u0441\u043F\u043E\u043B\u044C\u0437\u0443\u0435\u043C\u0441\u044F \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u043E\u043C \u0442\u043E\u043B\u044C\u043A\u043E \u0432 \u043E\u0441\u043E\u0431\u044B\u0445 \u0441\u043B\u0443\u0447\u0430\u044F\u0445.", typeInput: "auth" })) }), _jsx("div", { children: _jsx(Button, { onClick: handleUpdateUserInfo, type: "primary", children: "\u041F\u0440\u043E\u0434\u043E\u043B\u0436\u0438\u0442\u044C" }) })] }) }) }));
};
export { PersonalData };
