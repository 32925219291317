import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Dialog, Text } from '@/shared/ui';
import { PersonalData } from '@/widgets/ui/AuthFlow/ui/PersonalData/PersonalData';
import { SalesExperience } from '@/widgets/ui/AuthFlow/ui/SalesExperience/SalesExperience';
import { useTypedDispatch } from '@/shared/hooks/useTypedDispatch/useTypedDispatch';
import { setUpdateUserInfo } from '@/entity/ModalsEntity/modals.slice';
import { sendAmplitudeData } from '@/shared/utils/amplitude/amplitude';
const UpdateUserInfo = ({ open }) => {
    const dispatch = useTypedDispatch();
    const [step, setStep] = useState(0);
    const incrementStep = () => {
        sendAmplitudeData('profile_questrionnare_modal_view_continue_btn_clicked');
        setStep((prevState) => prevState + 1);
    };
    const handleClose = () => {
        if (step === 0) {
            sendAmplitudeData('profile_questrionnare_modal_view_close_btn_clicked');
        }
        else {
            sendAmplitudeData('profile_questionnare_modal_view_closed');
        }
        dispatch(setUpdateUserInfo(false));
    };
    return (_jsxs(Dialog, { open: open, closeIcon: true, css: { width: '70wv' }, onOpenChange: handleClose, children: [_jsx(Text, { css: { marginBottom: '24px' }, type: "preTitle", children: "\u0417\u0430\u043F\u043E\u043B\u043D\u0438\u0442\u0435 \u0434\u0430\u043D\u043D\u044B\u0435 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430" }), step === 0 &&
                _jsxs(_Fragment, { children: [_jsx(Text, { type: "default", css: { marginBottom: '12px' }, children: "\u041C\u044B \u043E\u0431\u043D\u0430\u0440\u0443\u0436\u0438\u043B\u0438, \u0447\u0442\u043E \u0443 \u0432\u0430\u0441 \u043D\u0435 \u0437\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u044B \u0434\u0430\u043D\u043D\u044B\u0435 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430. \u041F\u043E\u0436\u0430\u043B\u0443\u0439\u0441\u0442\u0430, \u0443\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u0435\u0434\u043E\u0441\u0442\u0430\u044E\u0449\u0443\u044E \u0438\u043D\u0444\u043E\u0440\u043C\u0430\u0446\u0438\u044E" }), _jsx(PersonalData, { incrementStep: incrementStep })] }), step === 1 &&
                _jsx(SalesExperience, { handleCloseModal: handleClose })] }));
};
export { UpdateUserInfo };
