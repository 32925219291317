import { styled } from '@/app/styles/mainStyles';
import { Input } from '@/shared/ui/Input/style';
export const PasswordInput = styled(Input, {});
export const Label = styled('div', {
    display: 'flex',
});
export const ButtonPassword = styled('button', {
    outline: 'none',
    border: 'none',
    background: 'none',
    position: 'absolute',
    padding: '0',
    right: '0%',
    top: '29px',
    width: '24px',
    height: '24px',
    '&:hover': {
        cursor: 'pointer',
    },
});
